.my-sticky-header-column-table {
  /* height or max-height is important */
  /* specifying max-width so the example can
  * highlight the sticky column on any browser window */
  /* this will be the loading indicator */
}
.my-sticky-header-column-table td:last-child {
  /* bg color is important for td; just specify one */
  background-color: #fafafa !important;
}
.my-sticky-header-column-table tr th {
  position: sticky;
  /* higher than z-index for td below */
  z-index: 2;
  /* bg color is important; just specify one */
  background: lightyellow;
}
.my-sticky-header-column-table thead tr:last-child th {
  /* height of all previous header rows */
  top: 30px;
  /* highest z-index */
  z-index: 3;
}
.my-sticky-header-column-table thead tr:first-child th {
  top: 0;
  z-index: 1;
}
.my-sticky-header-column-table tr:first-child th:first-child {
  /* highest z-index */
  z-index: 3;
}
.my-sticky-header-column-table td:first-child {
  height: 30px;
  z-index: 1;
}
.my-sticky-header-column-table td:last-child, .my-sticky-header-column-table th:last-child {
  position: sticky;
  right: 0;
  padding: 2px 5px;
}
.my-sticky-header-column-table th, .my-sticky-header-column-table td {
  padding: 2px 5px !important;
}
.my-sticky-header-column-table thead tr, .my-sticky-header-column-table tbody td {
  height: 30px !important;
}

.my-sticky-header-table {
  /* height or max-height is important */
  /* this is when the loading indicator appears */
}
.my-sticky-header-table .q-table__top,
.my-sticky-header-table .q-table__bottom,
.my-sticky-header-table thead tr:first-child th {
  /* bg color is important for th; just specify one */
}
.my-sticky-header-table thead tr th {
  position: sticky;
  z-index: 1;
  background-color: lightyellow;
}
.my-sticky-header-table thead tr:first-child th {
  top: 0;
}
.my-sticky-header-table th, .my-sticky-header-table td {
  padding: 2px 5px !important;
}
.my-sticky-header-table thead tr, .my-sticky-header-table tbody td {
  height: 30px !important;
}
.my-sticky-header-table.q-table--loading thead tr:last-child th {
  /* height of */
  top: 30px;
}

.my-menu-link {
  color: #FFFFFF !important;
  background: linear-gradient(to right, #606c88, #3f4c6b);
  min-height: 30px;
}

.my-tab-inline-label {
  padding: 0 10px;
}
.my-tab-inline-label .q-tab__content--inline .q-tab__icon + .q-tab__label {
  padding-left: 3px;
}

.main-headers {
  background: linear-gradient(to right, #606c88, #3f4c6b);
}

.main-page {
  background: linear-gradient(to right, #ece9e6, #ffffff);
}

.main-table {
  width: 100%;
  margin: -10px 10px 0 10px;
}

.main-table1 {
  width: 100%;
  margin: -30px 10px 0 10px;
}

.main-table2 {
  width: 100%;
  margin: -50px 10px 0 10px;
}

.main-table3 {
  width: 100%;
  margin: -30px 10px 0 10px;
}

.cordova-footer {
  margin-top: -15px;
}

.cordova-search {
  width: 130px;
}

.scan-background {
  background: #606c88 !important;
  color: white !important;
}